@tailwind base;

@tailwind components;

@tailwind utilities;

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.4s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.table-header {
    @apply px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider;
}
.table-header-right {
    @apply px-2 py-1 text-right text-xs font-medium text-gray-500 uppercase tracking-wider;
}

.table-data {
    @apply px-3 py-1 text-xs truncate max-w-xs;
}

.btn-sm {
    @apply inline-block mr-1 px-3 py-1 rounded shadow-lg lowercase text-white select-none cursor-pointer;
}

.btn {
    @apply inline-block mr-2 px-3 py-1 rounded shadow-lg tracking-wider uppercase text-white select-none cursor-pointer outline-none;
}
.btn-disabled {
    @apply inline-block mr-2 px-3 py-1 rounded tracking-wider uppercase text-white select-none bg-gray-600;
}
.btn:focus {
    @apply outline-none ring;
}

.btn-error {
    @apply bg-red-500;
}
.btn-error:hover {
    @apply bg-red-600;
}
.btn-error:active {
    @apply bg-red-700;
}

.btn-success {
    @apply bg-green-500;
}
.btn-success:hover {
    @apply bg-green-600;
}
.btn-success:active {
    @apply bg-green-700;
}

.btn-primary {
    @apply bg-primary-500;
}
.btn-primary:hover {
    @apply bg-primary-600;
}
.btn-primary:active {
    @apply bg-primary-700;
}

.checkbox {
    @apply focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded
}
.checkbox-cross {
    @apply focus:ring-red-500 h-4 w-4 text-gray-300 border-gray-300 rounded relative;
    color: red;
}
.checkbox-cross:checked {
    background: red;
}
.checkbox-cross:checked:after {
    @apply text-white absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    content: "X";
}

.alternate-row:nth-child(even) {
    @apply bg-gray-100
}

.alternate-row:nth-child(odd) {
    @apply bg-white;
}
.page {
    @apply inline-block p-1 mx-1 cursor-pointer align-middle;
}

.page:hover {
    @apply bg-white;
}

.input {
    @apply shadow-sm block w-full outline-none sm:text-sm p-2 rounded-md text-gray-800 border border-2;
}

.input:focus {
    @apply border-primary-500 ring-primary-500;
}

.input-sm {
    @apply max-w-lg block w-full outline-none p-1 rounded text-gray-800 sm:max-w-xs text-sm border border-2;
}
.input-sm:focus {
    @apply border-primary-500 ring-primary-500;
}

.input-select {
    @apply max-w-lg block focus:ring-primary-500 focus:border-primary-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md text-gray-600;
}

.input-error {
    @apply max-w-lg block ring-red-500 border-red-500 focus:ring-primary-500 focus:border-primary-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md text-gray-600;
}

.sm-input-select {
    @apply max-w-md block focus:ring-primary-500 focus:border-primary-500 w-full shadow-sm sm:max-w-xs text-xs border-gray-300 rounded-md p-1 text-gray-600;
}

.inactive {
    text-decoration: line-through red double;
}

.checkbox-mark:after {
    width: 30%;
    height: 70%;
    content: " ";
    left: 35%;
    top: 8%;
    transform: rotate(45deg);
    @apply hidden absolute border-white border-r-2 border-b-2;
}

.checkbox-mark:hover, input:focus ~ .checkbox-mark {
    @apply ring outline-none;
}

input:checked ~ .checkbox-mark:after {
    @apply block;
}

input:checked ~ .checkbox-mark {
    @apply bg-primary;
}

.overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(100, 100, 100, 0.4);
    backdrop-filter: blur(4px);
}

.rotate {
    -ms-writing-mode: tb-rl;
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
    white-space: nowrap;
}


[type=radio]:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
    @apply appearance-none w-4 h-4 rounded-full bg-center;
}